<template>
  <div>
   <b-modal
      id="output-by-attendance-modal"
      size="lg"
      hide-header
      hide-footer
      @hidden="closeModal"
      @show="onShow"
    >
      <div class="header d-flex flex-row justify-content-between">
        <p class="title">Saída de produto por atendimento</p>
        <v-close @click="closeModal" />
      </div>
      <div v-if="billItem" class="patient-props">
        <v-patient-data :patient="billItem.bill.patient"/>
      </div>

      <div class="information-procedures-wrapper">
        <v-info-circle class="icon-info"/>
        <div>
          <h3>Adicione e gerencie produtos utilizados no procedimento</h3>
          <p>
            Você pode selecionar os produtos que serão vendidos separadamente e não
            estão inclusos no valor do procedimento.
          </p>
        </div>
      </div>

      <!-- <div v-if="billItem">
        <div v-for="(clinicProcedure, index) in appointment.procedures" :key="clinicProcedure.id">
          <div class="procedure-header" @click="toggleProcedureOptions(clinicProcedure.id)">
            <span class="procedure-name">{{ `Procedimento ${normalizeProductNumber(index + 1, 2)}` }}</span>
            <v-chevron-down class="icon stroke" :class="{ 'opened-options': openedOption(clinicProcedure.id) }" />
          </div>
          <div v-if="openedOption(clinicProcedure.id)" class="procedure-content">
            <div class="content-header">
              <v-procedure-line :procedure="clinicProcedure" />
              <div class="procedure-quantity">
                {{ clinicProcedure.procedure_product[0].quantity ? calculateQuantity(clinicProcedure) : '00' }}
              </div>
            </div>
            <v-product-list-table
              v-if="forms[clinicProcedure.id]"
              :hiddenPrice="true"
              :editable="!forms[clinicProcedure.id].id"
              useType="PROCEDURE"
              :transactions="forms[clinicProcedure.id].transactions"
              @onAddProduct="addNewProduct(clinicProcedure.id)"
              @onEdit="(transaction) => editProductList(clinicProcedure.id, transaction)"
              @onDelete="(transaction) => removeProduct(clinicProcedure.id, transaction)"
            />

          </div>
        </div>
      </div> -->
      <div v-if="billItem" class="button-wrapper">
        <b-button
          variant="primary"
          @click="createWareHouseOutputs"
        >
          {{ getActionLabel() }}
        </b-button>
      </div>
    </b-modal>

    <v-product-transaction-modal
      identifier="attendance-warehouse-transaction-entry"
      type="OUTPUT"
      useType="PROCEDURE"
      :transaction="transaction"
      v-on:onSave="onModalAddProduct"
      v-on:onUpdate="onModalUpdateProduct"
    />

  </div>
</template>
<script>
import { track } from '@/utils/mixPanel'
import { normalizeProductNumber } from '@/utils/product-helper'
import { parseProcedureType } from '@/utils/procedure-helper'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import Close from '@/assets/icons/close.svg'
// import ChevronDown from '@/assets/icons/chevron-down-select.svg'
import InfoCircle from '@/assets/icons/info-circle.svg'
import PatientData from '@/components/General/PatientData'
// import ProcedureLine from '@/components/General/ProcedureLine'
// import ProductListTable from '@/components/Warehouse/ProductListTable'
import ProductTransactionModal from '@/components/Warehouse/ProductTransactionModal'

export default {
  components: {
    'v-close': Close,
    'v-patient-data': PatientData,
    // 'v-chevron-down': ChevronDown,
    'v-info-circle': InfoCircle,
    // 'v-procedure-line': ProcedureLine,
    // 'v-product-list-table': ProductListTable,
    'v-product-transaction-modal': ProductTransactionModal,
  },
  props: {
    billItem: Object,
    getPendingAttendanceProcedures: Function,
    openBillToReceiveProductModal: Function
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      openedIds: [],
      forms: {},
      errors: {},
      transaction: null,
      currentProcedureId: null,
    }
  },
  methods: {
    normalizeProductNumber,
    parseProcedureType,
    parseNumberToMoney,
    async onShow() {
      // await this.getProductSuggestionsByAttendance();
    },
    calculateQuantity(clinicProcedure){
      let value = 0
      clinicProcedure.procedure_product.map(el => {
        value += el.quantity
      })
      return normalizeProductNumber(value , 2)
    },
    getActionLabel() {
      const hasSale = Object.keys(this.forms).some(clinicProcedureId => this.forms[clinicProcedureId].transactions.some(transaction => transaction.checked));
      if (hasSale) {
        return 'Efetuar saída e gerar conta a receber'
      }
      return `Efetuar saída`
    },
    async getProductSuggestionsByAttendance() {
      const openedIds = [];
      try {
        await this.appointment.procedures.map(async ap => {
          await ap.procedure_product.map(async el => {
            openedIds.push(el.clinic_procedure_id);
            const data = await this.getOutputProductSuggestions(el);
            this.forms = {
              ...this.forms,
              [el.clinic_procedure_id]: {
                quantity: data.quantity,
                derivatives: data.derivatives,
                transactions: data.transactions
              }
            }
          })
        });
      } catch (err) {
        this.$toast.error(err.message);
      }
      this.openedIds = openedIds;
    },
    getOutputProductSuggestions(clinicProcedure) {
      return new Promise((resolve, reject) => {
        this.api.getOutputProductSuggestions(clinicProcedure.clinic_procedure_id, this.appointment.id)
          .then(res => resolve(res.data))
          .catch(reject);
        });
    },
    async createWareHouseOutputs() {
      const warehouseTransactions = [];
      const keys = Object.keys(this.forms);
      keys.map(clinicProcedureId => {
          const index = this.appointment.procedures.findIndex(p => p.id === clinicProcedureId)
          const form = this.forms[clinicProcedureId];
          const props = {
            clinic_id: this.clinic.id,
            date: new Date(),
            type: 'PROCEDURE',
            transactions: form.transactions,
            patient_id: this.appointment.patient_id,
            person_id: this.appointment.professional_id,
            health_plan_id: this.appointment.health_plan_id,
            plan_id: this.appointment.plan_id,
            procedure_id: clinicProcedureId,
          }
          if (this.isValidForm(index, props)) {
            warehouseTransactions.push(props);
          }
      });


      if (warehouseTransactions.length !== keys.length) return;

      const isLoading = this.$loading.show();
      try {
        warehouseTransactions.map(async props => {
          props.transactions.length && await this.createWareHouseOutput(props);
        });

        track('saida_de_produtos_por_atendimento', {
          clinic: this.clinic.name,
        });

        isLoading.hide();
        this.startBillToReceiveProductsModal(warehouseTransactions);

        this.solveAppointmentOutput();
        this.$bvModal.hide('output-by-attendance-modal');
        this.$toast.success('Saída realizada com sucesso!');
        this.getPendingAttendanceProcedures();
      } catch (err) {
        isLoading.hide();
        this.$toast.error(err.message);
      }
    },
    createWareHouseOutput(props) {
      return new Promise((resolve, reject) => {
        this.api.createWareHouseOutput(props)
        .then(resolve)
        .catch(reject);
      });
    },
    async solveAppointmentOutput() {
      await this.api.updateAppointment(this.appointment.id, { pending_warehouse: false });
    },
    startBillToReceiveProductsModal(warehouseTransactions) {
      const checkedTransactions = [];
      warehouseTransactions.map(wt => {
        wt.transactions.map(transaction => {
          if (transaction.checked) {
            const index = checkedTransactions.findIndex(ct => ct.product.id === transaction.product.id && ct.batch === transaction.batch);
            if (index >= 0) {
              checkedTransactions[index].quantity += transaction.quantity;
            } else {
              checkedTransactions.push({ ...transaction });
            }
          }
        });
      });

      if (!checkedTransactions.length) return;

      const billToReceive = {
        service_date: this.appointment.start_datetime,
        patient: this.appointment.patient,
        person: this.appointment.professional,
        health_plan: this.appointment.health_plan,
        plan: this.appointment.plan,
        transactions: checkedTransactions,
      };

      this.openBillToReceiveProductModal(billToReceive)
    },
    addNewProduct(currentProcedureId) {
      this.currentProcedureId = currentProcedureId;
      this.$bvModal.show('attendance-warehouse-transaction-entry');
    },
    editProductList(currentProcedureId, transaction) {
      this.currentProcedureId = currentProcedureId;
      this.transaction = Object.assign({}, transaction);
      this.$bvModal.show('attendance-warehouse-transaction-entry');
    },
    removeProduct(currentProcedureId, transaction) {
      this.currentProcedureId = currentProcedureId;
      const form = this.forms[this.currentProcedureId];
      const index = form.transactions.indexOf(transaction);
      form.transactions.splice(index, 1);
      this.forms = {
        ...this.forms,
        [this.currentProcedureId]: form,
      }
    },
    onModalAddProduct(transaction) {
      const form = this.forms[this.currentProcedureId];
      if (!form.transactions) {
        form.transactions = [];
      }
      const transactions = Object.assign([], form.transactions);
      const index = transactions.findIndex(t => t.product.id === transaction.product.id && t.batch === transaction.batch);
      index >= 0 ? transactions[index] = transaction : transactions.push(transaction);
      form.transactions = transactions;
      this.forms = {
        ...this.forms,
        [this.currentProcedureId]: form,
      }
    },
    onModalUpdateProduct(transaction) {
      const form = this.forms[this.currentProcedureId];
      form.transactions = form.transactions.map(t => {
        if (t.id === transaction.id) return transaction;
        return t;
      });
      this.forms = {
        ...this.forms,
        [this.currentProcedureId]: form,
      }
    },
    openedOption(clinicProcedureId) {
      return this.openedIds.includes(clinicProcedureId);
    },
    toggleProcedureOptions(clinicProcedureId) {
      const openedIds = Object.assign([], this.openedIds);
      if(this.openedOption(clinicProcedureId)) {
        this.openedIds = openedIds.filter(el => el !== clinicProcedureId);
        return;
      }
      openedIds.push(clinicProcedureId);
      this.openedIds = openedIds;
    },
    closeModal() {
      this.$bvModal.hide('output-by-attendance-modal');
    },
    isValidForm(index, form) {
      const errors = {};

      if (!form.patient_id) {
        errors.patient_id = true;
        this.$toast.warning(`Procedimento ${ index + 1} ` + 'Paciente não definido');
      }

      if (!form.person_id) {
        errors.person_id = true;
        this.$toast.warning(`Procedimento ${index + 1} ` + 'Profissional não definido');
      }

      if (form.health_plan_id && !form.plan_id) {
        errors.plan_id = true;
        this.$toast.warning(`Procedimento ${index + 1} ` + 'Plano de saúde não definido');
      }

      if (!form.procedure_id) {
        errors.procedure_id = true;
        this.$toast.warning(`Procedimento ${index + 1} ` + 'Procedimento não definido');
      }

      return !Object.keys(errors).length;
    }
  },

}
</script>
<style lang="scss">
#output-by-attendance-modal {
  .modal-content {
    border-radius: 8px !important;
  }
  .modal-dialog {
    width: 765px !important;
  }
  .modal-body {
    padding: 0 !important;

    .header {
      padding: 16px 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-family: 'Nunito Sans';
        font-weight: 600;
        font-size: 18px;
        margin: 0;
        color: var(--type-active);
      }

      svg {
        width: 24px;
        height: 24px;
        fill: var(--dark-blue);
      }
    }

    .patient-props {
      padding: 24px;
    }

    .information-procedures-wrapper {
      display: inline-flex;
      align-items: center;
      padding: 16px 24px;
      background-color: #E5F9FF;
      border-radius: 8px;
      color: var(--type-active);
      margin: 0 24px 24px 24px;

      .icon-info {
        width: 42px;
        height: 42px;
        stroke: #0088B2;
        margin-right: 26px;
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
      }

      p {
        font-style: normal;
        font-size: 16px;
      }
    }

    .procedure-header {
      padding: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--blue-100);
      margin-bottom: 4px;
      cursor: pointer;

      span {
        color: var(--neutral-700);
      }

      .icon {
        width: 35px;
        transition: all 500ms;
        stroke: var(--blue-500);
      }
      .opened-options {
        transform: rotate(-180deg);
      }

      .procedure-name {
        font-size: 18px;
        font-weight: 600;
        font-family: 'Nunito Sans';
        color: var(--neutral-700);
      }
    }
    .procedure-content {
      padding: 24px;

      .content-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .procedure-prefix {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-family: 'Nunito Sans';
        color: var(--blue-500);
      }
      .procedure-pipe {
        height: 15px;
        width: 2px;
        background-color: var(--blue-500);
        margin: 0 5px;
        border-radius: 50%;
      }
      .procedure-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        fill: var(--dark-blue);
      }
      .procedure-quantity {
        display: inline-block;
        padding: 12px 16px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 700;
        color: var(--type-active);
        background: var(--neutral-100);
      }
    }
    .button-wrapper {
      width: 100%;
      text-align: right;
      padding: 24px;
    }

    .more-with-menu {
      width: 24px;
      height: 28px;
      position: relative;
      display: inline-block;

      .more-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        transition: all 0.5s;
        cursor: pointer;
      }

      .menu {
        width: 233px;
        position: absolute;
        top: 28px;
        right: 0;
        background: var(--neutral-000);
        border: 1px solid var(--neutral-200);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;
        padding: 24px;
        opacity: 0;
        transition: all 0.5s;
        flex-direction: column;
        align-items: start;
        display: none;

        .mtb-12 {
          margin: 12px 0;
        }

        .btn {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px;

          &.remove {
            color: var(--states-error);
          }
        }
      }

      &:hover {
        .more-icon {
          transform: rotate(90deg);
        }

        .menu {
          opacity: 1;
          display: flex;
          z-index: 10;
        }
      }
    }
  }
}
</style>
